<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <CouponWriteForm />
    </Layout>
  </div>
</template>

<script>
import Vue from 'vue';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header.vue';
import CouponWriteForm from '@/components/coupon/CouponWriteForm.vue';

export default Vue.extend({
  components: {
    Layout,
    PageHeader,
    CouponWriteForm
  },
  data() {
    return {
      title: 'Coupon Add',
      items: [
        {
          text: 'Sucoding',
          href: '/'
        },
        {
          text: 'Coupon',
          href: '/'
        },
        {
          text: 'Coupon Add',
          active: true
        }
      ]
    };
  }
});
</script>
